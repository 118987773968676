import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Power Up Your Command Line IV",
  "date": "2019-05-07T18:00:00.000Z",
  "layout": "post",
  "draft": false,
  "path": "/posts/command-line-tools-iv",
  "category": "Software",
  "tags": ["command line", "productivity", "tips"],
  "description": "A showcase of 5 command line tools you can use to speed up your workflows."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Here are 5 command line tools I've found recently which can speed up your workflow.`}</p>
    <h2><inlineCode parentName="h2">{`fx`}</inlineCode>{`, a command-line JSON processing tool`}</h2>
    <p><inlineCode parentName="p">{`fx`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/antonmedv/fx"
      }}>{`GitHub`}</a>{`) is a CLI tool written in JavaScript which allows you to process JSON using your terminal.`}</p>
    <p><img parentName="p" {...{
        "src": "/ac79062c1bf549a380ddcb7529770178/fx.gif",
        "alt": "fx"
      }}></img></p>
    <p>{`You can explore and modify JSON using small snippets of plain JavaScript, and use your cursor to dive into the structure. For all of the functionality offered by `}<inlineCode parentName="p">{`fx`}</inlineCode>{`, check out the `}<a parentName="p" {...{
        "href": "https://github.com/antonmedv/fx/blob/master/DOCS.md"
      }}>{`documentation`}</a>{`.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`fx`}</inlineCode></h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`brew install fx`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`http-prompt`}</inlineCode>{`, for interacting with APIs`}</h2>
    <p><inlineCode parentName="p">{`http-prompt`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "http://http-prompt.com/"
      }}>{`website`}</a>{`) is useful for exploring and interacting with HTTP APIs. It comes with autocomplete and syntax highlighting.`}</p>
    <p><img parentName="p" {...{
        "src": "/6e2237706a9543b8d5e2cb29c8f4387a/http-prompt.gif",
        "alt": "http-prompt"
      }}></img></p>
    <p><inlineCode parentName="p">{`http-prompt`}</inlineCode>{` is written in Python, and builds on top of `}<a parentName="p" {...{
        "href": "https://httpie.org/"
      }}>{`HTTPie`}</a>{`, which was covered earlier in this series.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`http-prompt`}</inlineCode></h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`pip install --user http-prompt`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`fselect`}</inlineCode>{`, for querying files with an SQL-like syntax`}</h2>
    <p><inlineCode parentName="p">{`fselect`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/jhspetersson/fselect"
      }}>{`GitHub`}</a>{`) is an alternative way to search your filesystem. It lets you use a syntax similar to SQL to find what you're looking for. For example, to find the size and path of all `}<inlineCode parentName="p">{`.cfg`}</inlineCode>{` and `}<inlineCode parentName="p">{`.tmp`}</inlineCode>{` files in `}<inlineCode parentName="p">{`/home/user/`}</inlineCode>{`:`}</p>
    <p><inlineCode parentName="p">{`fselect size, path from /home/user where name = '*.cfg' or name = '*.tmp'`}</inlineCode></p>
    <p><inlineCode parentName="p">{`fselect`}</inlineCode>{` also supports aggregation functions, similar to those you find in SQL:`}</p>
    <p><inlineCode parentName="p">{`fselect "MIN(size), MAX(size), AVG(size), SUM(size), COUNT(*) from /home/user/Downloads"`}</inlineCode></p>
    <p>{`The query above will find the smallest file size, the largest file size, the average file size, the total file size, and the number of files present in your `}<inlineCode parentName="p">{`Downloads`}</inlineCode>{` folder.`}</p>
    <p><inlineCode parentName="p">{`fselect`}</inlineCode>{` is written in Rust, and it can do much more than the examples above suggest. It has extensive `}<a parentName="p" {...{
        "href": "https://github.com/jhspetersson/fselect"
      }}>{`documentation`}</a>{`.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`fselect`}</inlineCode></h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`brew install fselect`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`ranger`}</inlineCode>{`, a command-line file manager`}</h2>
    <p><inlineCode parentName="p">{`ranger`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/ranger/ranger"
      }}>{`GitHub`}</a>{`) is a command-line file manager written in Python that lets you browse and manipulate your file system using Vim like keybindings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "578px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/77974c48366220004ad69e0a060c5b1c/508ef/ranger.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.760736196319016%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACO0lEQVQozxXQyW7aQAAAUBeCMQnBNhi8jBfsGXtmPF4ZLxgDIZGqIlWKlLZSjyU9Njmm97ZfXkV6X/AE2uaYp1FeUs6bXV83fVk0T4+Pn8/ntqoIRHGEsziOURg4LnJ9Y2UosqoqqiwrwjoktruONvt1SAAAlgsXqzUjpOIFL/JtXeWMtXVVFXmRMAIRMIENbABsRVGFABPget3pY7vrIUJ5uYlIGtOkSNOqLJsN7+qG52XfdjwtMsq0hbbUlgAATdME07Ixoc8/ny+Xy6fz+cflcrw7JjnLW1b1aVHFuweeV7R74GHqyvJUEscTSZLGkiiOBZTl/en+7e33y+vrr5eXv3/+ff3+xUYrxADJbMSsiBhwbfqO5hjKQp7K1zfX0mQsioPBldA9fcsOx7LmEaMmAEXDIUGbhnf7brvblvWGJVnKyigIE0wyQnEQMEwhRBCGAqHID/zmfl9sK4hCfmhJEqMY0YL6JNB1Z7kE2tLU1Llnmq5lGZqmyup0OlNkVTj0JM3IbneMKeNVfX96iDAxsGWFYO7oE1mRxjNJnN5MrvX5Yn4ri6PxcDAaDK4Gg6EQogAiVPd9WVdJnrf7niSxkVsmA0qkiebtzUxR9JWsqvLtTBIn4jtpJI5HV6Jw6HpMacmzJGcYoiShQQRdsvZif+6bc11fesAivul5sjKfTKaiKA2How8fhu9hBWeU4Xrbdsdd0zX93b5um9Pd6XA4OQ4KIIlwglmmGw4McJqVlmXbtus4nm7a/wEan2HFFjpdkgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ranger",
            "title": "ranger",
            "src": "/static/77974c48366220004ad69e0a060c5b1c/508ef/ranger.png",
            "srcSet": ["/static/77974c48366220004ad69e0a060c5b1c/222b7/ranger.png 163w", "/static/77974c48366220004ad69e0a060c5b1c/ff46a/ranger.png 325w", "/static/77974c48366220004ad69e0a060c5b1c/508ef/ranger.png 578w"],
            "sizes": "(max-width: 578px) 100vw, 578px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`It offers a multi-column display, the ability to preview files, and lets you perform common file operations (such as creation, deletion, chmod, copying, etc.) from within the ranger interface.`}</p>
    <p>{`You can extend `}<inlineCode parentName="p">{`ranger`}</inlineCode>{` by installing some Python packages which allow it to preview images, HTML documents, and PDF files. The image below is an example posted on the `}<a parentName="p" {...{
        "href": "https://ranger.github.io/screenshots.html"
      }}>{`gallery`}</a>{` available on the Ranger website, showing the image previewing functionality in action.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/429e408dbe15c371035b95824e5bde59/8c557/ranger-screen.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC50lEQVQozx3D32sbBQDA8Xtem7mtS5PmcnfJ3eXucunlkrvLJbn8aHKXZMnaqmsVqlTFTVBp6dDRUseE9UEGdVoYCIoyBX1xe9vDkIHuRdgEEXzYgw/67Mv+hq/gBz6CGraR6k3kWhfFi5G9BNkboYRjCu0JcmeC3I7JG1X0coBuuvh+n95kk+7wEvlSjYzhopoNNNNHMKIZcnABLRhSbfepdmOMzgRzMKUUz9AmL6LFEyw1wNMaqFmFWbDKyf59fnvwD8cH3yBmW+TEBkqhhaA4TRbUGopdJ4xC6p0e1V5MkMS4yQh7PMXoD0n8DWytjioZbCSX+fHOTzx/9i+/3/sVW++SE0NEKUSwHAej6qMt1/FbAZWwQykcYkUJ5d4IczCm3JvgmRG6VELL6bwyepPvju7y4KPPefjZ95i6h5y3kfIOQl6zEUsORcuhGboEzQC11iXjJKTdMUvBBcxwgCFZVHWXmlJld2OPk8sHPL79Nbd2blKxE1TZRVzSEVJZjfklk6ViBct2UCyPnNkkbfY4aw45XxkhGR4lUUMXNYqLKrsv73Fj7XWeP3rC8c4tqmrMrHcFpzxEOJ0pMpcpoZd14hWNVmQStCu0e8u0Vmq0+suEoYEuWUhpkaZe5XDrGtfX3+L+jdvoso9+3qVbv8RLox2E04sKp9IqqqEy7BZohhquZ1BvWNieTdmv4IUuclZFOiuyt7bNz1894mK4RRJcJPITasUmDa3N1F9FSC3KzKUVMlKRkqmSV0tkCyaLhTLzuQqpvENOtZjUB3y4foW9zfe5c/1Lfrn7mKtbB6xHWyTRJmXVx7O7CKkzCqdSCmJWwdEVpFyBbFblXLrECwsG5zIGlmxxvP0pf377lL9/+IMv3jvh6O1P+OveM7Z7+2zG+zT0NWqFKcKcm2PeE8lECmJfYSGSONPMMxeI/58P82QjhdVXp1w9+oDD449599o7dF5bZffmIcF0xsr2G5THA/SViP8AZal6LqTBBz4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ranger-screen",
            "title": "ranger-screen",
            "src": "/static/429e408dbe15c371035b95824e5bde59/a6d36/ranger-screen.png",
            "srcSet": ["/static/429e408dbe15c371035b95824e5bde59/222b7/ranger-screen.png 163w", "/static/429e408dbe15c371035b95824e5bde59/ff46a/ranger-screen.png 325w", "/static/429e408dbe15c371035b95824e5bde59/a6d36/ranger-screen.png 650w", "/static/429e408dbe15c371035b95824e5bde59/8c557/ranger-screen.png 700w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Ranger is a deep piece of software with countless features that don't fit within the scope of this post. More information can be found in the `}<a parentName="p" {...{
        "href": "https://github.com/ranger/ranger/wiki/Official-user-guide"
      }}>{`official user guide`}</a>{`.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`ranger`}</inlineCode></h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`brew install ranger`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`tokei`}</inlineCode>{`, to view statistics on your code`}</h2>
    <p><inlineCode parentName="p">{`tokei`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/XAMPPRocky/tokei"
      }}>{`GitHub`}</a>{`) lets you view code statistics for your projects by breaking down the languages you've used. It's written in Rust, which helps make it `}<a parentName="p" {...{
        "href": "https://github.com/XAMPPRocky/tokei/blob/master/COMPARISON.md"
      }}>{`very fast`}</a>{`.`}</p>
    <p>{`Here's some example output from running `}<inlineCode parentName="p">{`tokei`}</inlineCode>{` in my `}<a parentName="p" {...{
        "href": "https://adventofcode.com/"
      }}>{`Advent of Code`}</a>{` folder:`}</p>
    <pre><code parentName="pre" {...{}}>{`-------------------------------------------------------------------------------
 Language            Files        Lines         Code     Comments       Blanks
-------------------------------------------------------------------------------
 Markdown                1          165          165            0            0
 Rust                   13         1071          854           51          166
 Plain Text             17         4032         4032            0            0
 TOML                    1           11           10            0            1
-------------------------------------------------------------------------------
 Total                  32         5279         5061           51          167
-------------------------------------------------------------------------------
`}</code></pre>
    <h3>{`Installing `}<inlineCode parentName="h3">{`tokei`}</inlineCode></h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`brew install tokei`}</inlineCode></li>
    </ul>
    <h2>{`Conclusion`}</h2>
    <p>{`Thanks for reading! Hopefully you found something on this page that interests you. If you’re interested in more content like this, follow me on `}<a parentName="p" {...{
        "href": "https://twitter.com/_darrenburns"
      }}>{`Twitter`}</a>{` and on `}<a parentName="p" {...{
        "href": "https://dev.to/_darrenburns"
      }}>{`DEV`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      